.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
   border: none;
   background: none;
   font-size: 15px;
   font-weight: 500;
   font-family: 'Poppins', sans-serif;
 
   color: var(--primary-color);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content #aa {
  color: black;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  display: block;
}
.dropdown-content #aa:hover {
  color: rgb(64, 72, 144);
  
  }
.dropdown:hover .dropdown-content {
  display: block;
}
