.esec{
    width: 80%;
    margin: auto;
}
.esec h2{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: justified;
    
}
.esec_row{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.esec_col1{
    flex-basis: 25%;

}
.esec_col1 img{
width: 100%;
}
.esec_col2{
flex-basis: 75%;    
}
.esec_col2 p{
    font-family: Poppins;
font-size: 24px;
font-weight: 400;
line-height: 34px;
letter-spacing: -0.02em;
text-align: justified;
color: black;
}
.erow{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.ecol{
    flex-basis: 49%;
}
.ecol input{
    width: 100%;
}
.esec button{
    font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: -0.02em;
text-align: left;
background: #162E4C;
color: #fff;
width: 20%;
text-align: center;
padding: 8px;
}
.esec2{
    background: #1B3659;

}
.esecc{
    width: 80%;
    margin: auto;
    padding: 14px;
}
.esecc h2{
    text-align: center;
    font-family: Manrope;
font-size: 35px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
color: #fff;
}
.esecc p{
    font-family: Manrope;
font-size: 22px;
font-weight: 400;
line-height: 44px;
letter-spacing: -0.5px;
text-align: center;
color: #fff;
}
.esecbook2{
text-align: center;
}
.esecbook2 img{
    width: 30%;
    text-align: center;
    margin-left: 5%;
}
.esecbook2 h2{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: justified;
    color: #1B3659;
}
.esecbook2 button{
    background: #1B3659;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: justified;
    color:white;
padding: 10px;
width: 150px;
}
@media (max-width:1200px) {
    .esec_row,.erow{
        flex-direction: column;
    }
    .ecol{
        width: 100%;
    }
    .esec button{
        width: 100%;

    }
}