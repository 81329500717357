@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@300;400;500;600;800&family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@300;400;500;600;800&family=Poppins:wght@400;500;600;700&display=swap');
.con_center{
    width: 80%;
    margin: auto;
}

.con_center h2{
    font-family: Manrope;
font-size: 40px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: left;

}
.con_center p{
    font-family: Poppins;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: justified;
color: black;

}

.con_row{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.con_col{
flex-basis: 29%;
background: #2A4B70;
padding: 20px;
}
.con_col h2{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
color: #fff;    
}
.con_col p{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}
.con_col2{
    flex-basis: 60%;
    }

    .con_rowico{
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    .can_colico{
    flex-basis: 10%;
    text-align: left;
    }
    .can_col2ico{
        flex-basis: 90%;
        text-align: left;
        }   

   .can_col2ico a, .can_colico i,.can_col2ico p{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #fff;
   }     


   .rowinp{
    display: flex;
    justify-content: space-between;
    align-items: center; 

   }
   .colinp{
flex-basis: 49%;
   }
   .colinp label,.con_col2 label,.mb label{
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

   }
   .colinp input,.con_col2 input,.mb input{
    border-top: none;
    border-left: none;
    border-right:none ;
    width: 100%;
   }
   .mb{
    display: none;
   }
   .mb button{
    padding: 2px;
    background: #2A4B70;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;

    }
   .con_col2 button{
margin-left: 70%;
padding: 2px;
background: #2A4B70;
color: #fff;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
width: 30%;
}
.last_con {
    width: 80%;
    margin: auto;
}
.last_con h2{
    font-family: Manrope;
font-size: 30px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: left;

}
.last_con p{
    font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: justified;
color: black;
}
.last_con h3{
    font-family: Poppins;
font-size: 28px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0em;
text-align: justified;
color: black;

}
.row__conlst{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}


.can_conlst{
flex-basis: 33%;
text-align: center;
}
.can_conlst{
    flex-basis: 33%;
    height: 80vh;
    text-align: center;
    background:linear-gradient(rgba(15, 14, 14, 0.5), rgba(15, 14, 14, 0.5)), url(./r1-min.png);
    background-position:center ;
    background-size: cover;

    }
    
    .can_conlst2{
        flex-basis: 33%;
        height: 80vh;
        text-align: center;
        background:linear-gradient(rgba(15, 14, 14, 0.5), rgba(15, 14, 14, 0.5)), url(./r2-min.png);
        background-position:center ;
        background-size: cover;
    }
    .can_conlst3{
        flex-basis: 33%;
        height: 80vh;
        text-align: center;
        background:linear-gradient(rgba(15, 14, 14, 0.5), rgba(15, 14, 14, 0.5)), url(./r3-min.png);
        background-position:center ;
        background-size: cover;
    
        }  
        .can_conlst h2,.can_conlst2 h2,.can_conlst3 h2{
            font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
margin-top: 6%;
margin-left: 5%;
margin-right: 5%;
    }
    .can_conlst p,.can_conlst2 p,.can_conlst3 p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 5%;
        margin-right: 5%;
color: #fff;        
    }     

@media (max-width:1200px) {
    .con_row{
        flex-direction: column;
    }
    .rowinp{
        flex-direction: column;
        display: none;
    }
    .con_col2 button,.con_col2 label{
        display: none;
    }
    .mb {
        display: block;
    }
    .row__conlst{
flex-direction: column;
    }
    .can_conlst,.can_conlst2,.can_conlst3{
margin-top: 5%;
    }
    .can_conlst p,.can_conlst2 p,.can_conlst3 p{
        text-align: center;
        margin-bottom: 4%;
    }
}

