@font-face {
    font-family: "Segoe";
    src: url("./Segoe\ UI.woff");
    }

.blogaa_row{
    display: flex;
    justify-content: space-between;
 
}
.blogaa_cola{
    flex-basis: 75%;
    border-radius: 5px;
    padding: 10px;
}
.blogaa_colb{
    flex-basis: 30%;
    border-radius: 5px;

    padding: 10px;
}


.blog_sub_sec{

    width: 90%;
    margin: auto;
}

.blog_row_sec{
    display: flex;
    justify-content: space-between;
    align-items:first baseline; 

}
.blog_col_sec_a{
    flex-basis: 79%;
    padding: 10px;
}
.blog_col_sec_b{
    flex-basis: 20%;
    padding: 10px;
    text-align: center;
}
.blog_sub_sec p,#ppp{

    font-family: 'Segoe', Arial, sans-serif;

font-size: 18px;
font-weight: 400;
line-height: 33px;
letter-spacing: 0em;
text-align: justified;
color: black;

}
.blog_sub_sec h2{
    font-family: 'Segoe', Arial, sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: justified;
    color: black;
    margin-top: 15px;
    margin-bottom: 5px;
}
#h333{
    font-family: 'Segoe', Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: justified;
    color: black;
    margin-top: 10px;
    width: 40%;
    margin-bottom: 5px;
}
.blog_sub_sec h1{
    font-family: 'Segoe', Arial, sans-serif;
    font-size: 38px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: justified;
    color: black;
    margin-top: 25px;
    margin-bottom: 25px;
}
.box_blog{
    background: #FCA130;
     padding: 20px;
}
.box_blog h3{
    font-family: 'Segoe', Arial, sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.box_blog p{
    font-family: 'Segoe', Arial, sans-serif;
font-size: 22px;
font-weight: 400;
line-height: 33px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}
.box_blog button{
    border: 2px solid #DEEBFF;
    background: #DEEBFF;
    font-family: Segoe UI;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
padding: 10px;
}