
.blog_sec{
    width: 80%;
    margin: auto;
}
.blog_row{
    display: flex;
    justify-content: space-between;
    align-items: center; 

}

.blog_col{
    flex-basis: 23%;
    border: 1px solid #242535;
    border-radius: 5px;
    background: #242535;  
    padding: 10px;
    height: 470px;

}

.blog_col img{
    width: 100%;
    height: 30vh;
}
.blog_col h4{
    
font-family: Work Sans;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color:  #4B6BFB;
;
margin-top: 10px;
}
.blog_col h2{
    font-family: Work Sans;
font-size: 22px;
font-weight: 600;
line-height: 37px;
letter-spacing: 0em;
text-align: left;
color: #fff;
}

@media (max-width:1200px) {
    .blog_row{
        flex-direction: column;
        align-items: flex-start;
    }
    .blog_col{
        margin-top: 20px;
    }
}