.career{
    width: 80%;
    margin: auto;
}
.row_car{
    display: flex;
    justify-content: space-between;
  align-items: center;
}
.col_car1{
    flex-basis:33%;
    text-align: left;

}
.col_car2{
    flex-basis:67%;
    text-align: left;

}
.col_car1 h2{
    font-family: Manrope;
font-size: 30px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: left;
max-width: 300px;
}
.col_car2 p{
    font-family: Manrope;
font-size: 24px;
font-weight: 400;
line-height: 33px;
letter-spacing: -0.5px;
text-align: justified;
color: black;
}
@media (max-width:1200px) {
    .row_car{
        flex-direction: column;
        align-items: flex-start;
        
    }
}