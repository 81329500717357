.ln_top{

}
.ln_top_row{
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    margin-top: 2%;   
}
.ln_top_col{
    flex-basis:48%;
    text-align: left;
}
.ln_top_col img{
    width: 100%;
    height: 20vh;
}
.ln_top_col h3{
    font-family: Manrope;
    font-size: 22px;
    font-weight: 600;
    color: black;
    text-align: left;

}
hr.new1 {
    border-top: 2px solid rgb(5, 4, 4);
    color: black;
  }
  @media (max-width:1200px) {
    .ln_top_row{
    flex-direction: column;
    }     
  }
  @media (max-width:600px) {
    .ln_top{
display: none;
    }  
    
    #textt{
        display: none   ;
    }   
    .blogaa_row{
        flex-direction: column;
    }
  }