.footer__below{
    background: #FFFFFF;
    color:gray;
    width: 100%;
    text-align: center;
    padding: 100px;
    margin-top: 40px;
}
.footer__sec{
width: 100%;
margin: auto;
}

.row_footer{
    display: flex;
    justify-content: space-between;
      
    align-items: baseline; 
}
.col_footer{
    flex-basis:20%;
    text-align: left;
    margin-top: 2%;

}
.col_footerb{
    flex-basis:40%;
    text-align: left;
    margin-top: 2%;
    margin-right: 5%;

}
.col_footerb img{
    width: 30%;
}
.col_footerb p,.col_footer p,.col_footer a{

font-family: DM Sans;
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color:#6F6C90;

}
.col_footer h2{
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
}
@media (max-width:1200px) {
    .row_footer{
        flex-direction: column;
    }   
    .col_footer{
          margin-top: 40px;
    
    }
}
@media (max-width:320px) {

}