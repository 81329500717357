.row_bg2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col2_bg{
    flex-basis: 24%;
    background: rgba(255, 187, 224, 1);
    box-shadow: 4px 7px 20px 0px rgba(0, 0, 0, 0.12);

    box-shadow: -4px 7px 20px 0px rgba(0, 0, 0, 0.12);
    
}
.col2_bg img{
    width: 90%;
    margin-left: 5%;
}
.bg__color{
    background: rgba(255, 255, 255, 1);
    height: 100px;
   
}
.bg__color h2{
    font-family: Montserrat;
font-size: 16px;
font-weight: 500;
line-height: 19.5px;
text-align: left;
padding-top: 10px;
padding-left: 5%;
padding-bottom: 10px;
}