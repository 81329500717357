@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@300;400;500;600;800&family=Poppins:wght@400;500;600;700&display=swap');

.about__us_main{
    height: 80vh;
    background:linear-gradient(rgba(15, 14, 14, 0.5), rgba(15, 14, 14, 0.5)), url(./aboutm.png);
    background-position:center ;
    background-size: cover;

}
.about__us_main h1{
    font-family: Manrope;
    font-size: 45px;
    font-weight: 700;
    line-height: 93px;
    letter-spacing: 0px;
        text-align: center;
    padding-top: 150px;
    color: #fff;
    filter: brightness(100%);
}
.about__us_main p{
    font-family: Manrope;
font-size: 26px;
font-weight: 600;
line-height: 47px;
letter-spacing: 0px;
text-align: center;
color: white;
}
.about_center{
    width: 80%;
    margin: auto;
}
.row{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;  

}
.col{
    flex-basis:49%;
    text-align: left;
    margin-top: 2%;
}
.col img{
    width: 100%;

}
.col h2{
    font-family: Manrope;
font-size: 40px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: left;

}
#sp1{
    font-family: Manrope;
font-size: 22px;
font-weight: 700;
line-height: 33px;
letter-spacing: -0.5px;
text-align: justified;

}
.col p{
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.5px;
    text-align: justified;
    
}
.banner{
    background: #1C3659;
text-align: center;
padding: 30px;
}
.banner h2{
    font-family: Manrope;
font-size: 30px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: center;
color: #fff;

}
.banner button{
  font-size: 16px;  
padding: 11px 41px 11px 41px;
border-radius: 10px;
gap: 10px;
font-family: Manrope;
color: black;
}
.aboutlast{
    width: 80%;
    margin: auto;
}
.aboutlast h2{
    font-family: Manrope;
    font-size: 30px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #000000;

    
}
#h222a{
    font-family: Manrope;
font-size: 40px;
font-weight: 700;
line-height: 54.64px;
letter-spacing: -0.5px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
#h222b{
    font-family: Manrope;
font-size: 24px;
font-weight: 400;
line-height: 32.78px;
letter-spacing: -0.5px;
text-align: justified;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-top: 20px;
}
.row2{
    display: flex;
    justify-content: space-between;
  
}
.coll{
    flex-basis:33%;
    text-align: left;
    margin-top: 2%;
}
.coll img{
    width: 100%;
}
.coll h2{
font-family: Manrope;
font-size: 26px;
font-weight: 500;
line-height: 49px;
letter-spacing: -0.5px;
text-align: left;
color: #000000;
}
.coll p{
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
       text-align: left;
    color: #000000;
    }

   .newsec{
    width: 80%;
    margin: auto;
   } 
   .rown{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
   }
   .colnn{
    flex-basis: 69%;
   }
   .colnn h3{
    font-family: Manrope;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
   }
   .colnn p{
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
color: #000000;
}
.colnn button{
    width: 23%;
    background: #CE8E32;
    font-family: Rubik;
font-size: 20px;
font-weight: 500;
line-height: 28.44px;


color: #fff;
height: Fixed (56px)px;
top: 3429px;
left: 409px;
padding: 10px;
gap: 10px;
border-radius: 5px;
border-style: none;
}
.colnnb{
    flex-basis: 30%;
}
.colnnb img{
    width: 100%;
}
    @media (max-width:1200px) {
        .rown{
   flex-direction: column;
        }
        .row2{
         flex-direction: column;
         align-items: flex-start;
          
        }
        .about__us_main h1{
            font-family: Manrope;
            font-size: 25px;
            font-weight: 700;
            padding: 20px;
            line-height: 43px;
            letter-spacing: 0px;
                text-align: center;
            padding-top: 200px;
            color: #fff;
            filter: brightness(100%);
        }
    }   

    @media (max-width:700px) {
        .about__us_main h1{
            font-weight: 700;
            letter-spacing: 0px;
                text-align: center;
            padding-top: 150px;
            color: #fff;
            filter: brightness(100%);
        }
        .about__us_main p{
            font-family: Manrope;
        font-size: 22px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        color: white;
        }
    }

    @media (max-width:320px) {
        
        .about__us_main h1{
            font-weight: 700;
            letter-spacing: 0px;
                text-align: center;
            padding-top: 100px;
            color: #fff;
            filter: brightness(100%);
        }
        .about__us_main p{
            font-family: Manrope;
        font-size: 16px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        color: white;
        }
    }
    