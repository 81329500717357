@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&display=swap');


.invest_centerb{
width: 80%;
margin: auto;
}
.res_row{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    
}
.res_col{
    flex-basis:49%;
    text-align: center;
}
.res_col h2{
    text-align: left;
    font-family: DM Sans;
}
.ress{
    width: 200px;
    background: #003889;
    text-align: center;
    padding: 10px;
    border-radius: 15px;
    color: #F4B545;
    font-weight: 700;
    font-family: DM Sans;
}
@media (max-width:1200px) {
    .res_row{
       flex-direction: column;
       align-items: flex-start;
    }
    .ress{
margin-top: 40px;
    }
 }