@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@300;400;500;600;800&family=Poppins:wght@400;500;600;700&display=swap');
body{
    
    overflow-X: hidden;
}
html {
    overflow-x: hidden;
  }
  
  body {
    position: relative;
    overflow-x: hidden;

  }
.about__us{
    height: 100vh;
    background:linear-gradient(rgba(15, 14, 14, 0.5), rgba(15, 14, 14, 0.5)), url(./main.png);
    background-position:center ;
    background-size: cover;


}
.about__us h1{
    font-family: Manrope;
    font-size: 45px;
    font-weight: 700;
    line-height: 93px;
    letter-spacing: 0px;
        text-align: center;
    padding-top: 300px;
    color: #fff;
    filter: brightness(100%);
}
.about__us p{


font-family: Manrope;
font-size: 25px;
font-weight: 700;
line-height: 58px;
letter-spacing: 0px;
text-align: center;
color: #fff;
}
.mid_sec{
    width: 80%;
    margin: auto;
}
.row{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;  
    align-items: center; 
}
.col{
    flex-basis:49%;
    text-align: left;
    margin-top: 2%;

}
.col h2{
    font-family: Manrope;
    font-size: 30px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #000000;
     
}
.col p{
    font-family: Manrope;
font-size: 18px;
font-weight: 400;
line-height: 33px;
letter-spacing: -0.5px;
text-align: justified;
color: #000000;
}
.col img{
    width: 100%;
}
.last_sec{
    width: 80%;
    margin: auto;
}
.last_sec h2{
    font-family: Manrope;
font-size: 30px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: left;
color: #000000;
}
.last_sec p{
    font-family: Manrope;
font-size: 20px;
font-weight: 400;
line-height: 33px;
letter-spacing: -0.5px;
text-align: justified;
color: #000000;
}
.last__sec2{
    background: #1B3659;

}
.last_sec_md{
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 30px;
}
.last_sec_md h2{
    font-family: Manrope;
font-size: 40px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;

color: #fff;
}
.last_sec_md p{
    font-family: Manrope;
font-size: 25px;
font-weight: 400;
line-height: 44px;
letter-spacing: -0.5px;
text-align: center;
color: #fff;;
}
.last_sec_md button{
    padding: 10px;
    color: #1B3659;
    font-family: Manrope;
    font-size: 17px;
    font-weight: 600;
margin-top: 10px;
    text-align: center;
}
@media (max-width:1200px) {
    .row{
        flex-direction: column;
        align-items: flex-start;
        
    }
    .about__us h1{
        font-family: Manrope;
        font-size: 26px;
        padding-top: 250px;
        
    }
    .about__us p{
      font-size: 20px;
    }
}