@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&display=swap');


.faq_center{
    width: 80%;
    margin: auto;
}

.des{
  border: 1px solid #003889;
width: 100%;
opacity: 1;
margin-top: 5%;
padding: 40px;
}

.des h2{
    font-family: DM Sans;
font-size: 32px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0px;
text-align: left;
color: #003889;
;

}
.des p{
    font-family: DM Sans;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #003889
;
}



details{
  border: 1px solid #003889;    color: #003889;
    font-family: DM Sans;

  font-size: 1.5rem;
}

summary {
  padding: .5em 1.3rem;
  list-style: none;
  display: flex;
  justify-content: space-between;  
  transition: height 3s ease;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after{
  content: "\002B";
}

details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: .5em;
}

details[open] summary:after{
  content: "\00D7";
}

details[open] div{
  padding: .5em 1em;
}
.faq__top h1{
  font-family: Manrope;
font-size: 30px;
font-weight: 700;
line-height: 35px;
letter-spacing: 0px;
text-align: left;
color: #003889;
margin-top: 50px;
}
.faq__top h2{

font-family: Manrope;
font-size: 25px;
font-weight: 700;
line-height: 35px;
letter-spacing: 0px;
text-align: left;
color: #003889;
margin-top: 25px;
}
.faq__top p{
  font-family: Manrope;
font-size: 20px;
font-weight: 500;
line-height: 35px;
letter-spacing: 0px;
text-align: left;
color: black;
margin-top: 10px;
}