.invest_center{
    width: 80%;
    margin: auto;
    margin-top: 10%;
}
.row_inv{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.col_inv{
    flex-basis:30%;
 }
 .col_inv img{
    width: 40%;
 }
 .col_inv h2{
    font-family: Manrope;
    font-size: 22px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #003889;

 }
 .col_inv p{
    font-family: Manrope;
font-size: 15px;
font-weight: 600;
line-height: 33px;
letter-spacing: -0.5px;
text-align: justified;
color: black;
 }

 .col_inv hr {
    border: 5px solid #F4B545;
    border-radius: 5px;
   opacity: 1;

  
}
  
@media (max-width:1200px) {
   .row_inv{
      flex-direction: column;
      align-items: flex-start;
   }
}