
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

:root {
  --card-bg-01: #fdefe6;
  --card-bg-02: #d6e5fb;
  --card-bg-03: #ceebe9;
  --card-bg-04: #e2f2b2;
  --primary-color: #0a1d37;
  --hero-bg: #d6e5fb;
  --heading-fontSize: 2rem;
  --small-text-color: #999;
  --heading-text-color: #0a1d37;
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

p {
  color: var(--small-text-color);
  font-size: 1rem;
}

h1,
h2 {
  font-size: var(--heading-fontSize);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}
