.page_main{
    width: 80%;
    margin: auto;
}
.page_main h2{
    font-family: Manrope;
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.02em;
    text-align: justified;

    
}
.page_main p{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: justified;
    color: black;
}
.page_row{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;  
    align-items: center; 
}
.col_page{
    flex-basis:49%;
    text-align: left;
    margin-top: 2%;

}
.col_page img{
    width: 100%;
}
.col_page h2{
    font-family: Manrope;
font-size: 26px;
font-weight: 700;
line-height: 49px;
letter-spacing: -0.5px;
text-align: left;

}
.col_page p{
    font-family: Poppins;
font-size: 17px;
font-style: italic;
font-weight: 500;
line-height: 34px;
letter-spacing: -0.02em;
text-align: justified;


}
.col_page button{
    font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 28px;
letter-spacing: -0.02em;
text-align: justified;
color: white;
background: #1B3659;
width: 40%;
}
.page_last{
    background: #1B3659;
}
.lst_text{
    width: 80%;
    margin: auto;
    padding: 20px;
}

.lst_text h2{
    font-family: Manrope;
font-size: 30px;
font-weight: 700;
line-height: 55px;
letter-spacing: -0.5px;
text-align: center;
color: #fff;
}
.lst_text p{
    font-family: Manrope;
font-size: 22px;
font-weight: 400;
line-height: 44px;
letter-spacing: -0.5px;
text-align: center;
color: #fff;

}
.lst_text button{
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 28px;
letter-spacing: -0.02em;
text-align: justified;
background: #fff;
padding: 10px;
margin-left: 40%;
margin-top: 2%;
}
.col_page ul li{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 34px;
letter-spacing: -0.02em;
text-align: justified;
list-style-type:circle;

}
.pg_r2{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;  
    align-items: center; 
}
.clpgr{
    flex-basis:32%;
    margin-top: 2%;
    background: #1B3659;
height: 40vh;
padding: 10px;
}
.clpgr h2{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: -0.02em;
    text-align: justified;
    text-align: center;
    margin-top: 10%;
    color: #fff;
}
.clpgr p{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #fff;
}

@media (max-width:1200px) {
    .page_row{
        flex-direction: column;
    }
    .lst_text button{
        width: 100%;
        margin-left: 0%;

    }
    .col_page button{
        width: 100%;
    }
    .pg_r2{
        flex-direction: column;
    }
}